.errmsg {
  position: fixed;
  top: 80%;
  left: 50%;
  margin-top: -100px;
  margin-left: -250px;
  width: 40vw;
  z-index: 2001;
}

.errmsg_header {
  display: flex;
  width: 100%;
}

.close_icon {
  cursor: pointer;
}
