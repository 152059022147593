html {
  font-weight: bold;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}
:root {
  --primary: #0471a1;
  --secondary: #c5dbe4;
  --tertiary: #3498db;
  --light: #ececec;
  --danger: #d91111;
  --muted-dark: #91918f;
  --bg: #fafafc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* scroll bar */
div::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

div::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

div::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #3f4141;
}

.rmdp-day.rmdp-selected {
  background-color: #3498db;
  color: #fff;
}
