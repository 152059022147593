.App {
  text-align: center;
  background: var(--bg)
}

/* big calender start */
.rbc-show-more {
  display: none;
}

.rbc-date-cell {
  padding-right: 1px !important;
}

@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles.css';
.rbc-current-time-indicator {
  display: none;
}

.rbc-calendar {
  min-height: calc(100vh - 70px);
}

/* .rbc-day-slot{
  display: flex !important;
} */
/* big calender end */

/* fonts */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.message {
  margin: 0;
}

.close {
  margin-left: 10px;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto-Regular'), url(../public/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto-Medium'), url(../public/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto-Bold'), url(../public/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins-Regular'), url(../public/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins-Medium'), url(../public/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins-Bold'), url(../public/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: local('DM Sans Regular'), url(../public/fonts/DM_Sans/static/DMSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: local('DM Sans Medium'), url(../public/fonts/DM_Sans/static/DMSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: local('DM Sans Bold'), url(../public/fonts/DM_Sans/static/DMSans-Bold.ttf) format('truetype');
}
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Noto+Sans+Arabic:wght@400;500;700&family=Tilt+Warp&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap'); */
/*  */

body {
  font-family: 'Poppins', sans-serif !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

.gAMYCL .head-div {
  padding: 24px 10px !important;
  align-items: center !important;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*  */
p {
  margin: 0 ;
  margin-bottom:0 !important;
}

/* DriverList Style */
.table-header {
  background-color: #8BC53F;
  border-radius: 5px 5px 0px 0px !important;
  box-shadow: 0px 3px 6px #00000029;
}

.table-body {
  box-shadow: 1px 1px 2px #fff;
  background-color: #fff;
}

.form-input-dashboard {

  border: 2px solid #8BC53F !important;
  background-color: transparent !important;

}

.page-item.active .page-link {
  background-color: #8BC53F !important;
  border-color: #8BC53F !important;
  color: #fff !important;

}


.page-link {
  color: #000000 !important;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: end;
}

.react-bs-table-sizePerPage-dropdown.dropdown {
  display: none !important;
}

.offcanvas-backdrop.show {
  opacity: 0 !important
}

.mouseClick {
  cursor: pointer;
}

th {
  text-align: center !important;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: transparent !important;
}

.accordion-button:focus {
  border-color: unset !important;

}

.accordion-button.collapsed {
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
}




.PzVRb {
  background-color: #fff !important;
  height: 115% !important;
}

.PzVRb .pro-sidebar .toggled {
  background-color: #fff !important;

}



.tabpurpleBluebg .nav-link.active {
  background: #80A6B8 !important;
  color: #000 !important;
  border-radius: 4px;

}

.tabpurpleBluebg .nav-link {
  color: #fff !important;
}




.nav-link {
  color: #000 !important;
}

select:focus::-ms-value {
  background-color: white !important;
  color: #000;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: transparent !important;
  border-style: solid;
  border-width: unset !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 15px;
}

thead {
  background-color: #fafafa !important;

}

tbody {
  font-size: 14px !important;
}

a.active>li {



  background: linear-gradient(#38B000, #759D2D);
}

.ejDRGY {
  position: fixed !important;


}

.kTwPWZ {
  background-color: #0D1724 !important;
}

.head-div {
  height: 130px !important;
}

.btn-green {
  background: linear-gradient(#38B000, #759D2D);
  border: unset !important;
}

.btn:focus {
  outline: 0;
  box-shadow: unset !important;
}

.tab-content {
  text-align: left !important;
}

/* input{
  
  margin: 0 10px 0 10px !important;
} */
.form-label {
  margin-bottom: 0px !important;
  display: flex !important;
  align-items: center !important;

}

.btn-outline-green {
  border-color: #759D2D !important;
  background: #fff !important;
  color: #759D2D !important;
  outline: linear-gradient(#38B000, #759D2D) !important;
}

/* select
{
  margin: 0 10px 0 10px !important;
} */

.homeFirstSquad {
  background-image: linear-gradient(to right, #38B000, #38B000, #008000, #006400);
  color: #fff !important;
  border-radius: 6px;

}

.firstSquadCircle {
  background-color: #008000;
}

.homesecondSquad {
  background-image: linear-gradient(to right, #065276, #08618B);
  color: #fff !important;
  border-radius: 6px;

}

.secondSquadCircle {
  background-color: #065276;
}

.homethirdSquad {
  background-image: linear-gradient(to right, #FF515A, #D91111);
  color: #fff !important;
  border-radius: 6px;

}

.thirdSquadCircle {
  background-color: #D91111;
}

.homeforthSquad {
  background-image: linear-gradient(to right, #F7C961, #E67E2E);
  color: #fff !important;
  border-radius: 6px;

}

.forthSquadCircle {
  background-color: #E67E2E;
}

.borderRaduis {
  border-radius: 10px;
  width: 150px !important;

}

.gmnoprint {
  display: none !important;
}

/* .accordion-button>h6::after { 
  content: "\f022";
  display: flex;
  justify-content: end;
  background-color: yellow;
  color: red;
  font-weight: bold;
} */

.bg-mainColorOverMap {
  background-color: rgba(255, 255, 255, .9);
  box-shadow: '0px 2px 4px #00000029';
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.text-mainColor {
  color: #065276 !important;
  font-size: 16px;
  font-weight: bolder;
}

.text-twentySize {
  color: #065276 !important;
  font-size: 18px;

}

p.text-twentySize {
  margin-bottom: 2px !important;
}

.hambozo {
  background-color: white !important;
  width: 300px !important;
  height: 50px !important;
  left: 70% !important;
  bottom: 10% !important;
  position: relative;
}

.hambozo-parent {
  width: 100px !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

div.relative-img {
  position: relative;


}

div.absolute-img {
  position: absolute;
  bottom: 20%;
  right: -10%;
  width: 100px;
  height: 10px;
}

.card {
  box-shadow: 0px 3px 6px #00000029;
  border: unset !important;

}

.card-heading {
  border-top-left-radius: 15px !important;
}

.dv-star-rating {
  font-size: 14px !important;
}

.dv-star-rating-input.fa.fa-star {
  font-size: 14px !important;
}

.groupPrice {
  border-bottom: 1px solid #C9C9C9;
  justify-content: space-between;
  align-items: center
}

.priceValue {
  background-color: '#E67E2E';
  border-radius: '5px';
}

.priceValueSign {
  background-color: #E67E2E;
  border-radius: 5px;
}

.priceValueCode {
  background-color: #003696;
  border-radius: 5px;
}

.priceValueBase {
  background-color: #08618B;
  border-radius: 5px;
}

.priceValueKm {
  background-color: #006400;
  border-radius: 5px;
}

.priceValueMin {
  background-color: #065276;
  border-radius: 5px;
}

.priceValueVAT {
  background-color: #F7C961;
  border-radius: 5px;

}

.bg-gray {
  background-color: #F0F0F0 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
}

/* input {
  border: 1px solid #8F8F8F !important;
} */

.addCountry-modal {
  width: 130% !important;
  right: -14% !important;
  left: unset !important;
}

.form-select {
  border: 1px solid #8F8F8F !important;
}

.modal-content {
  width: 115% !important;
}

.modal-header {
  border-bottom: 1px solid #8F8F8F !important;
}

.btn-close {
  border-radius: 50%;
  border: 2px solid #000000 !important;
  border-radius: 50% !important;
  font-size: 8px !important;
  color: black !important;
  font-weight: bolder !important;
}

select:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.like-button {
  font-size: 1rem;
  padding: 5px 10px;
  color: #585858;
}

.liked {
  font-weight: bold;
  color: #1565c0;
}


.MuiDrawer-paper {
  background-color: #111827 !important;
  color: #fff !important;
}

/* .MuiSvgIcon-root {
  color: #fff !important;
} */

.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
  width: 70px !important;
  background-color: #111827 !important;
  left: 0 !important;
  transition: unset !important;
  width: 40px !important;
}

.css-1191obr-MuiPaper-root-MuiAppBar-root {
  transition: unset !important;
  box-shadow: unset !important;
  box-sizing: unset !important;
  width: 40px !important;
}

@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 100px !important;
    transition: unset !important;
  }
}

.css-168noda {
  background-color: transparent !important;
  width: 150px;
  box-shadow: unset !important;
}

.css-vwd6ro {
  transition: unset !important;
  box-shadow: unset !important;
  box-sizing: unset !important;
  background-color: transparent !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

.gAMYCL .head-div {
  padding: 24px 10px !important;
  align-items: center !important;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

/* DriverList Style */
.table-header {
  background-color: #8BC53F;
  border-radius: 5px 5px 0px 0px !important;
  box-shadow: 0px 3px 6px #00000029;
}

.table-body {
  box-shadow: 1px 1px 2px #fff;
  background-color: #fff;
}

.form-input-dashboard {

  border: 2px solid #8BC53F !important;
  background-color: transparent !important;

}

.page-item.active .page-link {
  background-color: #8BC53F !important;
  border-color: #8BC53F !important;
  color: #fff !important;

}


.page-link {
  color: #000000 !important;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: end;
}

.react-bs-table-sizePerPage-dropdown.dropdown {
  display: none !important;
}

.offcanvas-backdrop.show {
  opacity: 0 !important
}

.mouseClick {
  cursor: pointer;
}

th {
  text-align: center !important;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: transparent !important;
}

.accordion-button:focus {
  border-color: unset !important;

}

.accordion-button.collapsed {
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
}




.PzVRb {
  background-color: #fff !important;
  height: 115% !important;
}

.PzVRb .pro-sidebar .toggled {
  background-color: #fff !important;

}



.tabpurpleBluebg .nav-link.active {
  background: #80A6B8 !important;
  color: #000 !important;
  border-radius: 4px;

}

.tabpurpleBluebg .nav-link {
  color: #fff !important;
}




.nav-link {
  color: #000 !important;
}

select:focus::-ms-value {
  background-color: white !important;
  color: #000;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: transparent !important;
  border-style: solid;
  border-width: unset !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 15px;
}

thead {
  background-color: #fafafa !important;

}

tbody {
  font-size: 14px !important;
}

a.active>li {



  background: linear-gradient(#38B000, #759D2D);
}

.ejDRGY {
  position: fixed !important;


}

.kTwPWZ {
  background-color: #0D1724 !important;
}

.head-div {
  height: 130px !important;
}

.btn-green {
  background: linear-gradient(#38B000, #759D2D);
  border: unset !important;
}

.btn:focus {
  outline: 0;
  box-shadow: unset !important;
}

.tab-content {
  text-align: left !important;
}

/* input{
  
  margin: 0 10px 0 10px !important;
} */
.form-label {
  margin-bottom: 0px !important;
  display: flex !important;
  align-items: center !important;

}

.btn-outline-green {
  border-color: #759D2D !important;
  background: #fff !important;
  color: #759D2D !important;
  outline: linear-gradient(#38B000, #759D2D) !important;
}

/* select
{
  margin: 0 10px 0 10px !important;
} */

.homeFirstSquad {
  background-image: linear-gradient(to right, #38B000, #38B000, #008000, #006400);
  color: #fff !important;
  border-radius: 6px;

}

.firstSquadCircle {
  background-color: #008000;
}

.homesecondSquad {
  background-image: linear-gradient(to right, #065276, #08618B);
  color: #fff !important;
  border-radius: 6px;

}

.secondSquadCircle {
  background-color: #065276;
}

.homethirdSquad {
  background-image: linear-gradient(to right, #FF515A, #D91111);
  color: #fff !important;
  border-radius: 6px;

}

.thirdSquadCircle {
  background-color: #D91111;
}

.homeforthSquad {
  background-image: linear-gradient(to right, #F7C961, #E67E2E);
  color: #fff !important;
  border-radius: 6px;

}

.forthSquadCircle {
  background-color: #E67E2E;
}

.borderRaduis {
  border-radius: 10px;
  width: 150px !important;

}

.gmnoprint {
  display: none !important;
}

/* .accordion-button>h6::after { 
  content: "\f022";
  display: flex;
  justify-content: end;
  background-color: yellow;
  color: red;
  font-weight: bold;
} */

.bg-mainColorOverMap {
  background-color: rgba(255, 255, 255, .9);
  box-shadow: '0px 2px 4px #00000029';
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.text-mainColor {
  color: #065276 !important;
  font-size: 16px;
  font-weight: bolder;
}

.text-twentySize {
  color: #065276 !important;
  font-size: 18px;

}

p.text-twentySize {
  margin-bottom: 2px !important;
}

.hambozo {
  background-color: white !important;
  width: 300px !important;
  height: 50px !important;
  left: 70% !important;
  bottom: 10% !important;
  position: relative;
}

.hambozo-parent {
  width: 100px !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

div.relative-img {
  position: relative;


}

div.absolute-img {
  position: absolute;
  bottom: 20%;
  right: -10%;
  width: 100px;
  height: 10px;
}

.card {
  box-shadow: 0px 3px 6px #00000029;
  border: unset !important;

}

.card-heading {
  border-top-left-radius: 15px !important;
}

.dv-star-rating {
  font-size: 14px !important;
}

.dv-star-rating-input.fa.fa-star {
  font-size: 14px !important;
}

.groupPrice {
  border-bottom: 1px solid #C9C9C9;
  justify-content: space-between;
  align-items: center
}

.priceValue {
  background-color: '#E67E2E';
  border-radius: '5px';
}

.priceValueSign {
  background-color: #E67E2E;
  border-radius: 5px;
}

.priceValueCode {
  background-color: #003696;
  border-radius: 5px;
}

.priceValueBase {
  background-color: #08618B;
  border-radius: 5px;
}

.priceValueKm {
  background-color: #006400;
  border-radius: 5px;
}

.priceValueMin {
  background-color: #065276;
  border-radius: 5px;
}

.priceValueVAT {
  background-color: #F7C961;
  border-radius: 5px;

}

.bg-gray {
  background-color: #F0F0F0 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
}

/* input {
  border: 1px solid #8F8F8F !important;
} */

.addCountry-modal {
  width: 130% !important;
  right: -14% !important;
  left: unset !important;
}

.form-select {
  border: 1px solid #8F8F8F !important;
}

.modal-content {
  width: 115% !important;
}

.modal-header {
  border-bottom: 1px solid #8F8F8F !important;
}

.btn-close {
  border-radius: 50%;
  border: 2px solid #000000 !important;
  border-radius: 50% !important;
  font-size: 8px !important;
  color: black !important;
  font-weight: bolder !important;
}

select:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.like-button {
  font-size: 1rem;
  padding: 5px 10px;
  color: #585858;
}

.liked {
  font-weight: bold;
  color: #1565c0;
}

/* mui components */
/* input / date picker */
/* TODO check singledriver page ui */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  /* padding: 10.5px 14px !important; */
  border: none !important;
}

/* mui table */
.css-kyl09d-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

/* google map autocomplete */
.pac-container {
  z-index: 13000 !important;
}

/* mui pagination */

.css-wjh20t-MuiPagination-ul {
  border-radius: .25rem !important;
}

/* .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root{
  color: #158894 !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #158894 !important;
  background-color: rgb(193 235 227) !important;
  border-radius: 50% !important;
} */

.css-1pqbehs-MuiButtonBase-root-MuiPaginationItem-root,
.css-sniopm {

  background-color: rgb(233, 233, 233) !important;
  color: rgb(0, 0, 0) !important;

}

.css-1pqbehs-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-sniopm.Mui-selected {
  /* border-radius: 0 !important; */
  color: white !important;
  background-color: #8BC53F !important;
}

/* .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root{
  color: black !important;
} */
/* spinner */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50, -50);
}

/* loader */
.loader_container {
  width: 350px;
  height: 350px;
  border-radius: 100%;
  background: linear-gradient(165deg, rgba(255, 255, 255, 1) 0%, rgb(220, 220, 220) 40%, rgb(170, 170, 170) 98%, rgb(10, 10, 10) 100%);
  position: relative;
}

.loader,
.loder_one,
.loader_two,
.loader_three {
  background-color: #006400;
}

.loader:before {
  background-color: #000;
  position: absolute;
  left: 0%;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-bottom: 0 solid #ffffff05;

  box-shadow:
    0 -10px 20px 20px #ffffff40 inset,
    0 -5px 15px 10px #ffffff50 inset,
    0 -2px 5px #ffffff80 inset,
    0 -3px 2px #ffffffBB inset,
    0 2px 0px #ffffff,
    0 2px 3px #ffffff,
    0 5px 5px #ffffff90,
    0 10px 15px #ffffff60,
    0 10px 20px 20px #ffffff40;
  filter: blur(3px);
  animation: 2s rotate linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

.spinner_body {
  margin: 0px;
  background: radial-gradient(#CECECE, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* spinner two */
/* .headerbar {
  background-color: #f7f7f7
}
.loading-container {
  display: flex;
  flex-wrap: wrap;
}

.custom-class {
  top: 50%;
  z-index: 7777;
  max-height: 1200px;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:transparent;
  border-radius: 3px;
}
.spin-container {
  text-align: center;
 
}
.spin {
  border: 3px solid #314516;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader2 {
  border: 3px solid #8BC53F;
  width: 220px;
  height: 220px;
  position: relative;
  top: -216px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader3 {
  border: 3px solid #526FC4;
  width: 240px;
  height: 240px;
  position: relative;
  top: -452px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
#loader4 {
  border: 3px solid #67912F;
  width: 260px;
  height: 260px;
  position: relative;
  top: -708px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}
@keyframes rotate {
  0% {
    transform: rotateZ(-360deg)
  }
  100% {
    transform: rotateZ(0deg)
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg)
  }
  100% {
    transform: rotateZ(0deg)
  }
}
#text {
  color: #ABFF00;
  font-family: Arial;
  font-size: 15px;
  position: relative;
  top: -857px;
}
.geeks {
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.geeks span {
  font-family: -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto,Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  color: #ABFF00;
  display: inline-block;
  transition: all 0.5s;
  animation: animate 2s infinite;
}
.geeks span:nth-child(1) {
  animation-delay: 0.1s;
}
.geeks span:nth-child(2) {
  animation-delay: 0.2s;
}
.geeks span:nth-child(3) {
  animation-delay: 0.3s;
}
.geeks span:nth-child(4) {
  animation-delay: 0.4s;
}
.geeks span:nth-child(5) {
  animation-delay: 0.5s;
}
.geeks span:nth-child(6) {
  animation-delay: 0.6s;
}
.geeks span:nth-child(7) {
  animation-delay: 0.7s;
}
.geeks span:nth-child(8) {
  animation-delay: 0.8s;
}
.geeks span:nth-child(9) {
  animation-delay: 0.9s;
}
.geeks span:nth-child(10) {
  animation-delay: 1s;
}
.geeks span:nth-child(11) {
  animation-delay: 1.1s;
}
.geeks span:nth-child(12) {
  animation-delay: 1.2s;
}
.geeks span:nth-child(13) {
  animation-delay: 1.3s;
} */

@keyframes animate {
  0% {
    color: #ABFF00;
    ;
    transform: translateY(0);
    margin-left: 0;
  }

  25% {
    color: #D6E3F6;
    transform: translateY(-15px);
    margin-left: 10px;
  }

  100% {
    color: #D6E3F6;
    transform: translateY(0);
    margin-left: 0;
  }
}

/*  */

/* mui alert */

.MuiAlert-icon {
  color: red !important;
}

/* login checkbox*/
.login_checkbox .MuiSvgIcon-root {
  color: #6c7375 !important;
}

/* add edit charger checkbox */
.charger_checkbox .MuiSvgIcon-root {
  color: #6c7375 !important;
}

/* .MuiSvgIcon-root{
  color: #6c7375 !important;
} */
/* mui tabs */
.css-heg063-MuiTabs-flexContainer {
  justify-content: space-around !important;
  background-color: white !important;
}

/* mui accordion */
.drawer_accordion_Summary .css-o4b71y-MuiAccordionSummary-content.Mui-expanded, .drawer_accordion_Summary .css-o4b71y-MuiAccordionSummary-content {
  margin:8px 0 !important; /* Set margin to 0 */
}
.MuiAccordionSummary-root {
  border-bottom: 1px solid #dedede !important;
}

.MuiAccordion-rounded {
  box-shadow: none !important;
}

.css-1xr9krm.Mui-selected {
  background-color: #8bc53f !important;
}

/* date picker */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

/* mui pagination */
input.check_box {
  height: 1.2rem !important;
  width: 1.2rem;
}

.dark {
  background-color: #333;
  color: #fff;
}

.light {
  background-color: #fff;
  color: #333;
}

/* text feild */
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input ~ .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top :0 !important;

  }
  .css-14lo706{
    height: 0px !important;
  } */
/* datagrid table pagination */
/* .css-13xqbvb,.css-106c1u2-MuiBadge-badge{
  display: none !important;
}
.css-1yy0dv2,
.css-17jjc08-MuiDataGrid-footerContainer {
  display: none !important;
} */
.disabled-car-row,.disabled-user-row{
  background-color: #d3d3d3 !important;
}
/* mui taps component */
.css-k008qs {
  justify-content: space-around !important;
}

.modal_header {
  /* padding: 0 30px; */
  color: var(--primary);
  display: flex;
  justify-content: space-between;
  background: inherit;
  min-height: 45px;
  align-items: center;
  width: 100%;
  position: relative;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: bold;
  border-radius: 15px;
}

html {
  font-size: 16px;
}

@media screen and (max-width: 1300px) and (min-width:900px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 899px) {
  html {
    font-size: 12px;
  }
}

/* mui data grid */
.MuiDataGrid-row.Mui-selected{
  background-color: var(--secondary) !important;
}
.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  border: none !important;
  border-top: 1px solid var(--bg) !important;
  border-bottom: 1px solid var(--bg) !important;
  /* font-weight: 500 !important; */
}

.css-oyfjzo-MuiDataGrid-root .MuiDataGrid-row {
  /* -webkit-box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px -3px rgba(0,0,0,0.75);  */
  margin: 0px 8px;
  /* font-weight: 500 !important; */

}

/* .css-1dpriho
,.css-1uyllb */
.css-1dpriho,.css-1uyllb,.css-oyfjzo-MuiDataGrid-root,.css-1e2bxag-MuiDataGrid-root
  {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: none;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.css-1rtnrqa,
.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: var(--primary) !important;
}

.css-1wlk0hk-MuiAvatar-root {
  -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
}

.table_button,
.open_add_modal_button {
  z-index: 1201 !important;
  display: flex;
  align-items: center;
  color: var(--primary) !important;
}

.table_button {
  position: absolute !important;
  top: 90px !important;
  right: 5%;
}

.data_grid_color_tip {
  /* position: absolute !important;
  top: 90px !important; */
  /* right: 5%; */
}

.header_title {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: bold;
  font-size: 1.8rem
    /* font-family: 'Noto Sans Arabic', sans-serif !important; */
}

/* cool card start*/
.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}

.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  /* flex-basis: calc(33.33333% - 30px); */

  margin: 1.5%;

  overflow: hidden;

  border-radius: 28px;
  width: 22%;
}

.ag-courses-item_link {
  display: flex;
  padding: 15px 10px;
  background-color: #121212;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;

  position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #FFF;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

.ag-courses-item_title {
  min-height: 87px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;
  color: #FFF;

  z-index: 2;
  position: relative;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #FFF;

  z-index: 2;
  position: relative;
}

.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}

.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}

.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}

.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}



@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }

  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}

@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }

  .ag-courses-item_link {
    padding: 22px 40px;
  }

  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

/* cool card end*/
/* mui tabs */
.css-19kzrtu {
  box-shadow: none !important;
}

/* mui select */
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,
.css-182didf.css-182didf.css-182didf {
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}

.blocked_data_grid_row {
  background-color: #F2DCDC !important;
}

.blocked_data_grid_row:hover {
  background-color: #F2DCDC !important;
}

.suspended_data_grid_row {
  background-color: #F8E6B6 ;
}

.suspended_data_grid_row:hover {
  background-color: #F8E6B6 !important;
}

/* delete modal start*/
.css-5e8q2m {
  min-height: fit-content !important;
}

/* delete modal end*/
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  width: 100%
}

.rbc-event-content {
  text-align: start !important
}

/* mui snackbar */
.css-cpgvjg-MuiSnackbar-root {
  left: 70px !important;
}


.chat_icon_wrapper {
  color: #a1d2c0;
  z-index: 1202 !important;
  position: fixed !important;
  bottom: 25px !important;
  border-radius: 50%;
  padding: 12px;
  background-color: #f9f9f9;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* mui tabs */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: var(--primary) !important;
}
/* mui select */
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
  min-height: 1.6375em !important;
}
/* google maps styles */
/* ?infowindo */
.gm-style-iw-c,.gm-style-iw-d{
  border-radius: 26px !important;
/* border: 1px solid rgba(0, 0, 0, 0.05)!important; */
background: #FFF!important;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;
display: inline-flex!important;
padding: 0!important;
flex-direction: column!important;
align-items: flex-start!important;
}
/*start bootstrap classes */
.btn-success{
  background-color: var(--primary) !important;
}

/*end bootstrap classes */
.bg_primary{
  background-color: var(--primary) !important;
}
.text_primary{
  color: var(--primary)
}
.page_header{
  font-weight: 500;
  font-size: 25px;
}

.centered_div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.bg-white{
  background-color: white !important;
}