.drawer {
    z-index: 1200;
    height: 100vh;
    background: #fff;
    transition: width .5s;
    position: fixed;
    bottom: 0;
    outline: 0;

    -webkit-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.75);
}

.drawer_header {

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: var(--secondary);
    position: static;
    left: 0;
}

/* .close_icon {
    cursor: pointer;
      position: absolute;
    top: 20px;
    right: 10px;
} */

.open_close_icon {
    /* position:absolute;  */
    /* top:4%; 
    left:50%; 
    transform:translate(-50%, -50%);  */
    cursor: pointer;
    margin: 17px 15px;
    color: black;
}

.logo {
    width: 50px;
}

.logo_wrapper {
    margin: 5px 15px;
    display: flex;
}

.nav_row {
    /* background-color: var(--secondary); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: .5rem 0;
    color: black;
    margin: 5px 0;
    font-size: 12px;
    font-weight: bold;
}

.nav_row:hover {
    background-color: var(--secondary);

}

.nav_row:active {
    background-color: #444955;
    color: white;

}

.nav_icon {
    font-size: 1.2rem;
    min-width: 60px;
    cursor: pointer
}

.nav_text {
    text-transform: capitalize;
    text-align: start;
    min-width: 180px;
    display:flex;
    align-items: center;
}

.opened_nav_text {
    text-transform: capitalize;
    text-align: start;
    min-width: 180px;
    display: block;
}

.closed_nav_text {
    display: none;
}

.selected_row {
    background-color: var(--primary);
    color: white !important;
}

.selected_row:hover {
    color: black !important;
}

.right_arrow,
.left_arrow {
    position: absolute;

}

.subLink_wrapper {
    /* background-color: white; */
    z-index: 3;
    position: absolute;
    background: #dde8ed;
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    /* height: 35px  */
}

.subLink {
    text-decoration: none;
    height: 40px;
    text-align: start;
    color: black;
    border-bottom: 1px solid rgb(173, 173, 173);
    width: 100%;
    margin: 0;
    padding-top: 8px;
    text-transform: capitalize;
}

.subLink:hover {
    background-color: var(--secondary);
border-radius: 8px;

}

.header {
    width: 100%;
    z-index: 1000;
    height: 65px;
    background-color: #3F4141;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 26px;
    position: sticky;
    top: 0;
}

.log_out {
    width: 148px;
    height: 39px;
    background-color: #fff;
    color: #3F4141;
    border-radius: 5px;

}

.profile_wrapper {
    position: fixed;
    bottom: 0px;
    box-shadow: -1px -3px 2px -1px rgba(0,0,0,0.09);
    max-height: 10%;
    z-index: 2;
    background-color: aliceblue;
    max-height: 8%;
}