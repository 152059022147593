.btn_outline_green {
  border-color: #759D2D !important;
  background: #fff !important;
  color: #759D2D !important;
  outline: linear-gradient(var(--primary), #759D2D) !important;
}

.submit_button {
  background-color: var(--primary);
  color: #fff;
  /* margin:27px 5px; */
  height: 45px;
  border-radius: 7px;
  border: none;
  padding: .5% 4%;
}

/* trip info modal styles */

.ellipsis_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 7px;
  border-radius: 4px;
  margin: 2px 0;

}

.price_distance_wrapper {
  padding: 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}





.user_card_wrapper{
  display: flex;
  justify-content: space-between;
  width:100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
}
.left_sec_wrapper{
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile_img{
  width: 170px;
  height: 170px;
  border-radius: 50%
}
.rating_wrapper{
  display: flex;
  margin-top: 10px;
}
.right_sec_wrapper{
  width:70%;
  display: flex;
  flex-wrap: wrap;
}
.profile_row_wrapper{
  width: 90%;
  margin: 0 20px 0 auto;
  display: flex;
  justify-content: space-between;
  }
  .icon_wrapper{
    max-width: 18px;
    max-height: 18px;
    margin: 0 15px 0 0;
  }
  .icon_wrapper_en{
    max-width: 18px;
    max-height: 18px;
    margin: 0 15px 0 0;
  }
  .icon_wrapper_ar{
    max-width: 18px;
    max-height: 18px;
    margin: 0 0 0 15px;
  }
  .tap_content_wrapper{
    width: 90%;
    margin: 10px auto;
  }
  .chart_wrapper{
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-top: 20px;
    padding: 0 10px;
  }
/* profile card end */
/* header */
.title{
  font-size: 1.8rem;
  }
  .icon_text_wrapper{
    display: flex;
    align-items: center;
    margin: 0;
}
.Header_container{
  height: 60px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 2.5%;
  align-items: center;
}
/* header */