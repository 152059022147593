.headerbar {
    background-color: #f7f7f7
  }
  .loading-container {
    display: flex;
    flex-wrap: wrap;
  }
  /* button {
    border: none;
    border-radius: 4px;
    padding: 6px;
    color: white;
    outline: none;
    font-weight: bold;
    font-size: 1.1em;
    margin: 15px 15px 15px 0;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    background-color: red;
    position: relative;
    top: -657px;
  } */
  .custom_class {
    top: 50%;
    z-index: 7777;
    max-height: 1200px;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:transparent;
    border-radius: 3px;
  }
  .spin-container {
    text-align: center;
   
  }
  .spin {
    border: 3px solid #FFB000;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
  }
  .loader2 {
    border: 3px solid var(--secondary)  !important;
    width: 220px !important;
    height: 220px !important;
    position: relative !important;
    top: -216px !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite !important;
  }
  .loader3 {
    border: 3px solid #F5582F !important;
    width: 240px!important;
    height: 240px!important;
    position: relative!important;
    top: -452px!important;
    border-left-color: transparent!important;
    border-right-color: transparent!important;
    animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite!important;
  }
  .loader4 {
    border: 3px solid var(--primary)!important;
    width: 260px!important;
    height: 260px!important;
    position: relative!important;
    top: -708px!important;
    border-left-color: transparent!important;
    border-right-color: transparent!important;
    animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite!important;
  }
  @keyframes rotate {
    0% {
      transform: rotateZ(-360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
  }
  @keyframes rotate2 {
    0% {
      transform: rotateZ(360deg)
    }
    100% {
      transform: rotateZ(0deg)
    }
  }
  .text {
    color: var(--primary) !important;
    font-family: Arial !important;
    font-size: 15px !important;
    position: relative !important;
    top: -857px !important;
    
  }
  .geeks {
    display: flex;
    justify-content: center;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  .geeks span {
    font-family: -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto,Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    color: var(--primary);
    display: inline-block;
    transition: all 0.5s;
    animation: animate 2s infinite;
  }
  .geeks span:nth-child(1) {
    animation-delay: 0.1s;
  }
  .geeks span:nth-child(2) {
    animation-delay: 0.2s;
  }
  .geeks span:nth-child(3) {
    animation-delay: 0.3s;
  }
  .geeks span:nth-child(4) {
    animation-delay: 0.4s;
  }
  .geeks span:nth-child(5) {
    animation-delay: 0.5s;
  }
  .geeks span:nth-child(6) {
    animation-delay: 0.6s;
  }
  .geeks span:nth-child(7) {
    animation-delay: 0.7s;
  }
  .geeks span:nth-child(8) {
    animation-delay: 0.8s;
  }
  .geeks span:nth-child(9) {
    animation-delay: 0.9s;
  }
  .geeks span:nth-child(10) {
    animation-delay: 1s;
  }
  .geeks span:nth-child(11) {
    animation-delay: 1.1s;
  }
  .geeks span:nth-child(12) {
    animation-delay: 1.2s;
  }
  .geeks span:nth-child(13) {
    animation-delay: 1.3s;
  }
  
  @keyframes animate {
    0% {
        color: var(--primary);
        transform: translateY(0);
        margin-left: 0;
    }
    25% {
        color: #D6E3F6;
        transform: translateY(-15px);
        margin-left: 10px;
    }
    100% {
        color: var(--secondary);
        transform: translateY(0);
        margin-left: 0;
    }
  }   
   